import React, { FunctionComponent, useEffect, useState } from "react";
import Select from "./base/Select";
import GetDropDowns from "./GetDropDowns";
import CalculationResult from "./CalculationResults";
import BuildUpImage from "./BuildUpImage";
import IApiDetails from "./IApiDetails";
import StringInput from "./base/StringInput";
import EmailInput from "./base/EmailInput";
import ErrorMessage from "./base/ErrorMessage";
import Button from "./base/Button";
import ReCAPTCHA from "react-google-recaptcha";
import CheckBox from "./base/CheckBox";
import { Link } from "react-router-dom";
import useCalculationStore from "../stores/useCalculationStore";

const Calculation: FunctionComponent<{}> = ({}) => {
  const [apiObject, setApiObject] = useCalculationStore();

  const isWeberProductCBValid = apiObject.weberProductCB !== "-1";
  const isSubstrateCBValid = apiObject.substrateCB !== "-1";
  const isInsulationCBValid = apiObject.insulationCB !== "-1";
  const isInsulationThicknessCBCBValid =
    apiObject.insulationThicknessCB !== "-1";

  useEffect(() => {
    (async () => {
      if (!!dropDownLists.externalWallInsulation[apiObject.weberProductCB]) {
        localStorage.setItem(
          "weberProductCB",
          dropDownLists.externalWallInsulation[apiObject.weberProductCB].item
        );
        console.log(apiObject.weberProductCB);
        console.log(
          dropDownLists.externalWallInsulation[apiObject.weberProductCB].item
        );
      }
    })();
  }, [apiObject.weberProductCB]);

  function NewCalculation() {
    setApiObject({
      ...apiObject,
      weberProductCB: "-1",
      insulationThicknessCB: "-1",
      insulationCB: "-1",
      substrateCB: "-1",
    });
    //setCalcState({ state: CalcState.inputData });
  }

  const dropDownLists = GetDropDowns();

  return (
    <>
      <div className="flex flex-col gap-6 col-start-1">
        <div className="grid gap-2">
          <Select
            label="External Wall Insulation System"
            defaultOptionLabel=""
            value={apiObject.weberProductCB}
            onChange={(newValue) =>
              setApiObject({ ...apiObject, weberProductCB: newValue })
            }
            options={
              dropDownLists.externalWallInsulation?.map(({ value, item }) => ({
                label: item,
                value,
              })) || []
            }
          />
        </div>

        {isWeberProductCBValid && (
          <div className="grid gap-2">
            <Select
              label="Substrate"
              defaultOptionLabel=""
              value={apiObject.substrateCB}
              onChange={(newValue) =>
                setApiObject({ ...apiObject, substrateCB: newValue })
              }
              options={
                dropDownLists.substrate?.map(({ value, item }) => ({
                  label: item,
                  value,
                })) || []
              }
            />
          </div>
        )}

        {isWeberProductCBValid &&
          isSubstrateCBValid &&
          apiObject.weberProductCB === "1" && (
            <div className="grid gap-2">
              <Select
                label="Insulation"
                defaultOptionLabel=""
                value={apiObject.insulationCB}
                onChange={(newValue) =>
                  setApiObject({ ...apiObject, insulationCB: newValue })
                }
                options={
                  dropDownLists.insulationXm?.map(({ value, item }) => ({
                    label: item,
                    value,
                  })) || []
                }
              />
            </div>
          )}

        {isWeberProductCBValid &&
          isSubstrateCBValid &&
          apiObject.weberProductCB === "2" && (
            <div className="grid gap-2">
              <Select
                label="Insulation"
                defaultOptionLabel=""
                value={apiObject.insulationCB}
                onChange={(newValue) =>
                  setApiObject({ ...apiObject, insulationCB: newValue })
                }
                options={
                  dropDownLists.insulationXp?.map(({ value, item }) => ({
                    label: item,
                    value,
                  })) || []
                }
              />
            </div>
          )}

        {isWeberProductCBValid &&
          isSubstrateCBValid &&
          isInsulationCBValid &&
          (apiObject.insulationCB === "0" ) && (
            <div className="grid gap-2">
              <Select
                label="Insulation"
                value={apiObject.insulationThicknessCB}
                onChange={(newValue) =>
                  setApiObject({
                    ...apiObject,
                    insulationThicknessCB: newValue,
                  })
                }
                options={
                  dropDownLists.thicknesses_30_200?.map(({ value, item }) => ({
                    label: item,
                    value,
                  })) || []
                }
              />
            </div>
          )}

        {isWeberProductCBValid &&
            isSubstrateCBValid &&
            isInsulationCBValid &&
            (apiObject.insulationCB === "1") && (
                <div className="grid gap-2">
                  <Select
                      label="Insulation"
                      value={apiObject.insulationThicknessCB}
                      onChange={(newValue) =>
                          setApiObject({
                            ...apiObject,
                            insulationThicknessCB: newValue,
                          })
                      }
                      options={
                          dropDownLists.thicknesses_30_200?.map(({ value, item }) => ({
                            label: item,
                            value,
                          })) || []
                      }
                  />
                </div>
            )}

        {isWeberProductCBValid &&
          isSubstrateCBValid &&
          isInsulationCBValid &&
          apiObject.insulationCB === "3" && (
            <div className="grid gap-2">
              <Select
                label="Insulation"
                defaultOptionLabel=""
                value={apiObject.insulationThicknessCB}
                onChange={(newValue) =>
                  setApiObject({
                    ...apiObject,
                    insulationThicknessCB: newValue,
                  })
                }
                options={
                  dropDownLists.thicknesses_40_100?.map(({ value, item }) => ({
                      label: item,
                    value,
                  })) || []
                }
              />
            </div>
          )}

        {isWeberProductCBValid &&
          isSubstrateCBValid &&
          isInsulationCBValid &&
          isInsulationThicknessCBCBValid && (
            <div className="grid gap-4 lg:grid-cols-2 mt-2">
              <Link
                className="font-bold flex items-center justify-center bg-weber px-5 py-3 rounded-full text-center hover:bg-weber-hover transition-colors"
                to="/create-report"
              >
                Enter details and create report
              </Link>
              <Link
                className="font-bold flex items-center justify-center bg-weber px-5 py-3 rounded-full text-center hover:bg-weber-hover transition-colors"
                to="/request-pip"
              >
                Request Project Information Pack
              </Link>
            </div>
          )}
      </div>

      <div className="grid gap-2">
        {apiObject.weberProductCB === "1" && apiObject.insulationCB === "0" && (
          <BuildUpImage filename="webertherm XM silicone-acrylic system (EPS) low res.jpg" />
        )}
        {apiObject.weberProductCB === "1" && apiObject.insulationCB === "1" && (
          <BuildUpImage filename="webertherm XM silicone-acrylic system (MFD) low res.jpg" />
        )}
        {apiObject.weberProductCB === "2" && apiObject.insulationCB === "0" && (
          <BuildUpImage filename="webertherm XP scraped system (EPS) low res.jpg" />
        )}
        {apiObject.weberProductCB === "2" && apiObject.insulationCB === "1" && (
          <BuildUpImage filename="webertherm XP scraped system (MFD) low res.jpg" />
        )}
        {isWeberProductCBValid &&
          isSubstrateCBValid &&
          isInsulationCBValid &&
          isInsulationThicknessCBCBValid && (
            <>
              <CalculationResult apiObject={apiObject} />
            </>
          )}
      </div>
      {isWeberProductCBValid &&
        isSubstrateCBValid &&
        isInsulationCBValid &&
        isInsulationThicknessCBCBValid && (
          <span className="col-span-full text-sm italic">
            This U-value estimation is provided to customers free of charge and
            is based on information you have entered. No duty of care is owed to
            the recipient or any other third party and Saint-Gobain Weber cannot
            accept liability in respect of the information shown. The insulation
            thicknesses have been calculated with the latest available Lambda
            values for the insulation material declared by the manufacturers.
            Estimates are based on generic components and need to be verified
            for any particular structure, components and system. It is essential
            that you should request specific calculations from Saint-Gobain
            Weber based on the actual materials to be used. For more information
            please call us on 01525 718877 or email
            <a
              className="App-link"
              href="mailto:email specifications@netweber.co.uk"
            >
              {" "}
              specifications@netweber.co.uk
            </a>
          </span>
        )}
    </>
  );
};

export default Calculation;
