import React, { FunctionComponent, useState } from "react";
import ProjectInformationRequest from "./components/ProjectInformationRequest";

    const TestPipApi: FunctionComponent<{}> = ({}) => {
        return(
            <ProjectInformationRequest
            BuildingType= {1}
            BuildingTypeOther={'Other'}
            AccessAvailableForPullOutTests= {new Date()}
            AnyCeilingsOrWalkwaysNeedingInsulation= {true}
            AnyPreviousStructuralIssues= {''}
            BuildingHeight= {1}
            BuildingUse= {1}
            CavityInsulationPresent= {true}
            Company= {''}
            ContactEmail= {''}
            ContactName= {''}
            ContactTelNumber= {''}
            DistanceToBoundary= {1}
            InsulationRequiredBelowDPC= {true}
            MainContractor= {''}
            NewBuild= {true}
            PreferredInsulationType= {1}
            Project= {''}
            RequestDate= {new Date()}
            RequestedBy= {new Date()}
            NoOfStoreys= {1}
            RoomForInsulationInTheReveals= {false}
            RoomInRoof= {true}
            SufficientOverhangOnCills= {true}
            SufficientOverhangAtEaves= {false}
            SystemFinishRequired= {''}
            ProjectAddress= {''}
            UvalueTarget= {1}
            WindLoads= {1}
            WindowReplacementProgram= {true}
            ThroughWallConstruction= {1}
            ProjectSize= {1}
                />
        )

}
export default TestPipApi;