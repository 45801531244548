import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Calculation from "./components/Calculation";
import RequestPip from "./components/RequestPiP";
import CreateReport from "./components/pages/CreateReport";
import CreateReportSuccess from "./components/pages/CreateReportSuccess";
import TestPipApi from "./TestPipApi";

function App() {
  return (
    <BrowserRouter>
      <div className="max-w-7xl p-8 mx-auto grid gap-8">
        <img src="/Weber_Logo_RGB.png" width={250}/>
        <div className="grid md:grid-cols-2 gap-8">
          <Routes>
            <Route path="/create-report/success" element={<CreateReportSuccess />} />
            <Route path="/create-report" element={<CreateReport />} />
            <Route path="/request-pip" element={<RequestPip />} />
            <Route path="/" element={<Calculation />} />
            <Route path="/testpipapi" element={<TestPipApi />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
