import { FunctionComponent } from "react";
import Select from "./Select";
import Label from "./Label";

const DateInput: FunctionComponent<{
  label: string;
  value?: Date;
  mandatory?: boolean;
  description?: string;
  labelSize?: "sm" | "lg";
  onChange: (arg0: Date) => void;
  minDate?: Date;
  maxDate?: Date;
}> = ({
  label,
  labelSize = "lg",
  description,
  value,
  minDate,
  maxDate,
  onChange,
  mandatory = false,
}) => {
  const startYear = minDate?.getFullYear() ?? 1901;
  const endYear = maxDate?.getFullYear() ?? new Date().getFullYear() + 100;

  const date = value ?? new Date();

  return (
    <fieldset>
      <Label
        type="legend"
        label={label}
        mandatory={mandatory}
        textSize={labelSize}
        description={description}
      />

      <div className="flex gap-4">
        <div className="flex flex-col flex-1">
          <Select
            label="Day"
            labelSize="sm"
            options={Array.from(Array(31).keys()).map((num) => {
              const dayNum = num + 1;
              return { label: dayNum + "", value: dayNum + "" };
            })}
            value={date.getDate().toString()}
            onChange={(dayOfMonth) => {
              const dateClone = new Date(date.valueOf());
              dateClone.setDate(parseInt(dayOfMonth));
              onChange(dateClone);
            }}
          />
        </div>
        <div className="flex flex-col flex-1">
          <Select
            label="Month"
            labelSize="sm"
            options={[
              { label: "January", value: "0" },
              { label: "February", value: "1" },
              { label: "March", value: "2" },
              { label: "April", value: "3" },
              { label: "May", value: "4" },
              { label: "June", value: "5" },
              { label: "July", value: "6" },
              { label: "August", value: "7" },
              { label: "September", value: "8" },
              { label: "October", value: "9" },
              { label: "November", value: "10" },
              { label: "December", value: "11" },
            ]}
            value={date.getMonth().toString()}
            onChange={(month) => {
              const dateClone = new Date(date.valueOf());
              dateClone.setMonth(parseInt(month));
              onChange(dateClone);
            }}
          />
        </div>
        <div className="flex flex-col flex-1">
          <Select
            label="Year"
            labelSize="sm"
            options={Array.from(Array(endYear - startYear + 1).keys()).map(
              (num) => {
                const yearNum = num + startYear;
                return { label: yearNum + "", value: yearNum + "" };
              }
            )}
            value={date.getFullYear().toString()}
            onChange={(year) => {
              const dateClone = new Date(date.valueOf());
              dateClone.setFullYear(parseInt(year));
              onChange(dateClone);
            }}
          />
        </div>
      </div>
    </fieldset>
  );
};

export default DateInput;
