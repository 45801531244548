import { uniqueId } from "lodash";
import { FunctionComponent, useEffect, useState } from "react";
import Label from "./Label";

const Select: FunctionComponent<{
  label: string;
  mandatory?: boolean;
  labelSize?: "sm" | "lg";
  description?: string;
  options: { label: string; value: string }[];
  value: string | null;
  onChange: (value: string) => any;
  defaultOptionLabel?: string;
}> = ({
  label,
  mandatory = false,
  labelSize = "lg",
  description,
  options,
  value,
  onChange,
  defaultOptionLabel,
}) => {
  const [id] = useState(uniqueId("select-"));

  useEffect(() => {
    if (
      !defaultOptionLabel &&
      (value === null || value === "") &&
      options.length > 0
    ) {
      onChange(options[0].value);
    }
  }, [defaultOptionLabel, value, options, onChange]);

  return (
    <>
      <Label
        type="label"
        label={label}
        mandatory={mandatory}
        textSize={labelSize}
        description={description}
        htmlFor={id}
      />
      <select
        id={id}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        className="px-3 py-2 bg-transparent border-gray-900 rounded border w-full focus:outline-light"
      >
        {defaultOptionLabel ? (
          <option value="" disabled>
            {defaultOptionLabel}
          </option>
        ) : null}

        {options.map(({ label, value }) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;
