import { uniqueId } from "lodash";
import { FunctionComponent, useState } from "react";
import Label from "./Label";

const NumberInput: FunctionComponent<{
  label: string;
  mandatory?: boolean;
  labelSize?: "sm" | "lg";
  description?: string;
  unit?: string;
  fullUnit?: string;
  value?: string;
  onChange?: (val: string) => void;
  min?: number;
  max?: number;
  step?: number;
}> = ({
  label,
  mandatory = false,
  labelSize = "lg",
  description,
  unit,
  fullUnit,
  value,
  onChange,
  min,
  max,
  step,
}) => {
  const [id] = useState(uniqueId("number-"));

  return (
    <>
      <Label
        type="label"
        label={label}
        mandatory={mandatory}
        textSize={labelSize}
        htmlFor={id}
        hiddenLabel={"in " + fullUnit}
        description={description}
      />
      <span className="flex items-center border border-gray-900 rounded focus-within:outline-light">
        <input
          id={id}
          type="number"
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={(e) => (onChange ? onChange(e.target.value) : null)}
          className="w-full px-3 py-2 bg-transparent focus:outline-none"
        />
        {unit && fullUnit ? (
          <>
            <span className="px-3 pl-1" aria-hidden="true" title={fullUnit}>
              {unit}
            </span>
          </>
        ) : null}
      </span>
    </>
  );
};

export default NumberInput;
