import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from "react";
import {stat} from "fs";
import Button from "./base/Button";
import {NavLink} from "react-router-dom";

const ProjectInformationRequest: FunctionComponent<{
    BuildingType: number;
    BuildingTypeOther: string;
    AccessAvailableForPullOutTests: Date;
    AnyCeilingsOrWalkwaysNeedingInsulation: boolean | null;
    AnyPreviousStructuralIssues: string;
    BuildingHeight: number;
    BuildingUse: number;
    CavityInsulationPresent:boolean | null;
    Company: string;
    ContactEmail: string;
    ContactName: string;
    ContactTelNumber: string;
    DistanceToBoundary: number;
    InsulationRequiredBelowDPC: boolean | null;
    MainContractor: string;
    NewBuild: boolean | null;
    PreferredInsulationType: number;
    Project: string;
    RequestDate: Date;
    RequestedBy: Date;
    NoOfStoreys: number;
    RoomForInsulationInTheReveals: boolean | null;
    RoomInRoof: boolean | null;
    SufficientOverhangOnCills: boolean | null;
    SufficientOverhangAtEaves: boolean | null;
    SystemFinishRequired: string;
    ProjectAddress: string;
    UvalueTarget: number;
    WindLoads: number;
    WindowReplacementProgram: boolean | null;
    ThroughWallConstruction: number;
    ProjectSize: number

}> = ({BuildingType,
       BuildingTypeOther,
          AccessAvailableForPullOutTests,
          AnyCeilingsOrWalkwaysNeedingInsulation,
          AnyPreviousStructuralIssues,
          BuildingHeight,
          BuildingUse,
          CavityInsulationPresent,
          Company,
          ContactEmail,
          ContactName,
          ContactTelNumber,
          DistanceToBoundary,
          InsulationRequiredBelowDPC,
          MainContractor,
          NewBuild,
          PreferredInsulationType,
          Project,
          RequestDate,
          RequestedBy,
          NoOfStoreys,
          RoomForInsulationInTheReveals,
          RoomInRoof,
          SufficientOverhangOnCills,
          SufficientOverhangAtEaves,
          SystemFinishRequired,
          ProjectAddress,
          UvalueTarget,
          WindLoads,
          WindowReplacementProgram,
          ThroughWallConstruction,
          ProjectSize
      }) => {

    const [apiResponse, setApiResponse] = useState<{
        sent: string;
        errors: Array<{
            value: string;
        }>;
        errorStr: string;
    }>({
        errors: [],
        sent: "",
        errorStr: ""
    });

    useEffect(() => {
        (async () => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(
                    { 'BuildingType': BuildingType,
                        'BuildingTypeOther': BuildingTypeOther,
                        'AccessAvailableForPullOutTests': AccessAvailableForPullOutTests,
                        'AnyCeilingsOrWalkwaysNeedingInsulation': AnyCeilingsOrWalkwaysNeedingInsulation,
                        'AnyPreviousStructuralIssues': AnyPreviousStructuralIssues,
                        'BuildingUse': BuildingUse,
                        'BuildingHeight': BuildingHeight,
                        'CavityInsulationPresent': CavityInsulationPresent,
                        'Company': Company,
                        'ContactEmail': ContactEmail,
                        'ContactName': ContactName,
                        'ContactTelNumber': ContactTelNumber,
                        'DistanceToBoundary': DistanceToBoundary,
                        'InsulationRequiredBelowDPC': InsulationRequiredBelowDPC,
                        'MainContractor': MainContractor,
                        'NewBuild': NewBuild,
                        'PreferredInsulationType': PreferredInsulationType,
                        'Project': Project,
                        'RequestDate': RequestDate,
                        'RequestedBy': RequestedBy,
                        'NoOfStoreys': NoOfStoreys,
                        'RoomForInsulationInTheReveals': RoomForInsulationInTheReveals,
                        'RoomInRoof': RoomInRoof,
                        'SufficientOverhangOnCills': SufficientOverhangOnCills,
                        'SufficientOverhangAtEaves': SufficientOverhangAtEaves,
                        'SystemFinishRequired': SystemFinishRequired,
                        'ProjectAddress': ProjectAddress,
                        'UvalueTarget': UvalueTarget,
                        'WindLoads': WindLoads,
                        'WindowReplacementProgram': WindowReplacementProgram,
                        'ThroughWallConstruction': ThroughWallConstruction,
                        'ProjectSize': ProjectSize})
            };
            console.log('ProjectInformationRequest UseEffect');
            const response = await fetch(`${process.env.REACT_APP_API_PATH}/api/ProjectInformationRequest`, requestOptions);
            const json = await response.json();
            setApiResponse(json);
        })();
    }, []);

    const myResponse = apiResponse;
    return(
        <div>
            {myResponse.sent == 'OK' && (
                <label >Submission sent successfully</label>
            )}

            {myResponse.errors ?
                (myResponse.errors.map(data => (<p>{data}</p>))) : null }

            {myResponse.sent != 'OK' && (
                <>
                <p>{myResponse.sent}</p>
                <p>{myResponse.errorStr}</p>
                </>
            )}

            <div className="grid gap-4 lg:grid-cols-2 mt-2">

                <NavLink
                    className="flex items-center justify-center bg-weber px-5 py-3 rounded-full text-center hover:bg-weber-hover transition-colors"
                    to="/"
                >
                    New Estimate
                </NavLink>
            </div>

        </div>)
}
export default ProjectInformationRequest;
