import React, { FunctionComponent,useEffect ,useState } from "react";
import CheckBox from "./base/CheckBox";
import Choice from "./base/Choice";
import DateInput from "./base/DateInput";
import NumberInput from "./base/NumberInput";
import Select from "./base/Select";
import StringInput from "./base/StringInput";
import useDownLists from "./GetDropDowns";
import ProjectInformationRequest from "./ProjectInformationRequest";
import Button from "./base/Button";
import validator from "validator";

import {useParams, useSearchParams} from "react-router-dom";
import MandatoryLabel from "./base/MandatoryLabel";

const RequestPip: FunctionComponent<{}> = ({}) => {
  const dropDownLists = useDownLists();

  const [form, setForm] = useState< {
    requestedBy: Date;
    company: string;
    project: string;
    projectSize: number;
    projectAddress: string;
    buildingUse: number | null;
    buildingType: number | null;
    buildingTypeOther: string;
    roomInRoof: boolean | null;
    newBuild: boolean | null;
    numberOfStoreys: number | null;
    mainContractor: string;
    distanceToBoundary: number;
    throughWallConstruction: number;
    buildingHeight: number;
    windLoads: number;
    uvalueTarget: number;
    dateAccessAvailableForPullOutTests: Date;
    contactName: string;
    contactTel: string;
    contactEmail: string;
    preferredInsulationType: number | null;
    anyPreviousStructuralIssues: string;
    cavityInsulationPresent: boolean | null;
    sufficientOverhangAtEaves: boolean | null;
    windowReplacementProgram: boolean | null;
    sufficientOverhangOnCills: boolean | null;
    roomForInsulationInTheReveals: boolean | null;
    insulationRequiredBelowDPC: boolean | null;
    anyCeilingsOrWalkwaysNeedingInsulation: boolean | null;
    }>(() => ({
    requestedBy: new Date(),
    company: "",
    project: "",
    projectSize: 0,
    projectAddress: "",
    buildingUse: null,
    buildingType: 0,
    buildingTypeOther: "",
    roomInRoof: null,
    newBuild: null,
    numberOfStoreys: null,
    mainContractor: "",
    distanceToBoundary:0,
    throughWallConstruction:0,
    buildingHeight:0,
    windLoads:0,
    uvalueTarget: 0.0,
    dateAccessAvailableForPullOutTests: new Date(),
    contactName: "",
    contactTel: "",
    contactEmail: "",
    cavityInsulationPresent: null,
    preferredInsulationType: null,
    anyPreviousStructuralIssues: "",
    sufficientOverhangAtEaves : null,
    windowReplacementProgram: null,
    sufficientOverhangOnCills : null,
    roomForInsulationInTheReveals: null,
    insulationRequiredBelowDPC : null,
    anyCeilingsOrWalkwaysNeedingInsulation: null
  }));
  //console.log(form);

  enum FormState {
    inputtingData,
    SubmitPressed,
  }
  function GetSystemFinish()
  {
    const item = localStorage.getItem("weberProductCB");
    if (item)
    {
      //console.log("Item -" + item);
      return(item);
    }
    else
    {
      //console.log("Item not found");
      return "";
    }
  }

    useEffect(() => {
      const uvalue = localStorage.getItem("uValue");
      if (uvalue !== null)
      {
        console.log("pip uValue - " + uvalue)
        setForm({...form, uvalueTarget: JSON.parse((uvalue))});
      }
    }, []);

  const [formState, setFormState] = useState({
    state: FormState.inputtingData,
  });

    enum CurrentPage {
        page1,
        page2,
        page3,
        page4,
        page5,
        page6,
        page7
    }

    const [currentPage, setCurrentPage] = useState({
        state: CurrentPage.page1,
    });

    const GotoPage1 = () => {
        setCurrentPage({state: CurrentPage.page1 });

    };
    const GotoPage2 = () => {
        setCurrentPage({state: CurrentPage.page2 });
    };
    const GotoPage3 = () => {
        setCurrentPage({state: CurrentPage.page3 });
    };
    const GotoPage4 = () => {
        setCurrentPage({state: CurrentPage.page4 });
    };
    const GotoPage5 = () => {
        setCurrentPage({state: CurrentPage.page5 });
    };
    const GotoPage6 = () => {
        setCurrentPage({state: CurrentPage.page6 });
    };
    const GotoPage7 = () => {
        setCurrentPage({state: CurrentPage.page7 });
    };
  function SubmitPressed()
  {
    setFormState({ state: FormState.SubmitPressed });
  }

  return (
      <>
          <div className="flex flex-col gap-10">
          <h1 className="text-4xl">EWI Project Information Pack Request</h1>
            <MandatoryLabel />

          {currentPage.state === CurrentPage.page1 &&
              formState.state === FormState.inputtingData && (
              <div className="flex flex-col gap-10">
                <div>
                  <h3 className="text-2xl">{GetSystemFinish()}</h3>
                </div>
              <div>
                  <StringInput
                      label="Contact name"
                      mandatory={true}
                      value={form.contactName}
                      onChange={(newValue) => setForm({...form, contactName: newValue})}
                  />
              </div>

              <div>
                  <StringInput
                      label="Contact number"
                      mandatory={true}
                      value={form.contactTel}
                      onChange={(newValue) => setForm({...form, contactTel: newValue})}
                  />
              </div>

              <div>
                  <StringInput
                      label="Contact email"
                      mandatory={true}
                      value={form.contactEmail}
                      onChange={(newValue) => setForm({...form, contactEmail: newValue})}
                  />
              </div>
              {   (form.contactName !== "") &&
                  (form.contactTel !== "") &&
                  (validator.isEmail(form.contactEmail)) &&
                  formState.state === FormState.inputtingData &&
                  (
                      <div>
                          <br/>
                          <br />

                          <Button
                              label="Next page"
                              onClickFunction={GotoPage2}
                          />
                      </div>
                  )}
          </div>
          )}

          {currentPage.state === CurrentPage.page2 &&
              formState.state === FormState.inputtingData &&(
              <div className="flex flex-col gap-10">

                  <div>
                      <DateInput
                          label="Requested by"
                          mandatory={true}
                          value={form.requestedBy}
                          onChange={(date) => setForm({ ...form, requestedBy: date })}
                          minDate={new Date()}
                      />
                  </div>

                  <div>
                      <StringInput
                          label="Company"
                          mandatory={false}
                          value={form.company}
                          onChange={(newValue) => setForm({...form, company: newValue})}
                      />
                  </div>

                  <div>
                      <StringInput
                          label="Project"
                          mandatory={true}
                          value={form.project}
                          onChange={(newValue) => setForm({...form, project: newValue})}
                      />
                  </div>

                  <div>
                      <NumberInput
                          label="Project size"
                          value={form.projectSize?.toString() ?? ""}
                          step={0.1}
                          unit="m²"
                          fullUnit="metres squared"
                          onChange={(newValue: string) =>
                              setForm({
                                  ...form,
                                  projectSize:
                                      parseInt(newValue),
                              })}

                      />
                  </div>

                  <div>
                      <StringInput label="Project address"
                                   mandatory={false}
                                   value={form.projectAddress}
                                   onChange={(newValue) => setForm({...form, projectAddress: newValue})}
                      />
                  </div>

                  <div>
                      <StringInput label="Main contractor"
                                   mandatory={false}
                                   value={form.mainContractor}
                                   onChange={(newValue) => setForm({...form, mainContractor: newValue})}
                      />
                  </div>

                  <div className="flex">
                      <Button
                          label="Previous page"
                          onClickFunction={GotoPage1}
                      />
                      {   (form.project !== "") &&
                          formState.state === FormState.inputtingData &&
                          (
                      <Button
                          label="Next page"
                          onClickFunction={GotoPage3}
                      />
                          )}
                  </div>
              </div>
          )}

          {currentPage.state === CurrentPage.page3 && (
              <div className="flex flex-col gap-10">


                  <Choice<number>
                      label="Building use"
                      mandatory={true}
                      multi={false}
                      options={dropDownLists.buildingUse.map(({ item, value }) => ({
                          label: item,
                          value: parseInt(value),
                      }))}
                      value={form.buildingUse}
                      onChange={(buildingUse) => setForm({ ...form, buildingUse })}
                  />

                  <Choice<number>
                      label="Building type"
                      mandatory={true}
                      multi={false}
                      options={dropDownLists.buildingType.map(({ item, value }) => ({
                          label: item,
                          value: parseInt(value),
                      }))}
                      value={form.buildingType}
                      onChange={(buildingType) => setForm({ ...form, buildingType })}
                  />
                {form.buildingType == 8 &&
                    <div>
                    <StringInput
                        label="Building type other"
                        mandatory={false}
                        value={form.buildingTypeOther}
                        onChange={(newValue) => setForm({...form, buildingTypeOther: newValue})}
                    />
                    </div>
                }

                <div>
                      <NumberInput
                          label="Distance to boundary"
                          value={form.distanceToBoundary?.toString() ?? ""}
                          unit="m"
                          fullUnit="metres"
                          onChange={(newValue: string) =>
                              setForm({
                                  ...form,
                                  distanceToBoundary:
                                      parseInt(newValue),
                              })}
                          mandatory={true}
                      />
                  </div>

                  <Choice<boolean>
                      label="New build or refurbishment?"
                      mandatory={false}
                      multi={false}
                      options={[
                          { label: "New build", value: true },
                          { label: "Refurb", value: false },
                      ]}
                      value={form.newBuild}
                      onChange={(newBuild) => setForm({ ...form, newBuild })}
                  />

                  <div>
                      <NumberInput
                          label="Through wall construction"
                          description="Each layer thickness from inside to out"
                          value={form.throughWallConstruction?.toString() ?? ""}
                          unit="mm"
                          fullUnit="millimetres"
                          onChange={(newValue: string) =>
                              setForm({
                                  ...form,
                                  throughWallConstruction:
                                      parseInt(newValue),
                              })}
                          mandatory={true}
                      />
                  </div>

                  <Choice<boolean>
                      label="Room in the roof?"
                      mandatory={false}
                      multi={false}
                      options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                      ]}
                      value={form.roomInRoof}
                      onChange={(roomInRoof) => setForm({ ...form, roomInRoof })}
                  />                  <div className="flex">
                      <Button
                          label="Previous page"
                          onClickFunction={GotoPage2}
                      />
                      {   (form.buildingUse|| 0 > 0) &&
                          (form.buildingType|| 0 > 0) &&
                          (form.distanceToBoundary > 0) &&
                          (form.throughWallConstruction > 0) &&
                          formState.state === FormState.inputtingData &&
                          (
                              <Button
                                  label="Next page"
                                  onClickFunction={GotoPage4}
                              />
                          )}
                  </div>
              </div>
          )}

          {currentPage.state === CurrentPage.page4 &&
              formState.state === FormState.inputtingData &&(
              <div className="flex flex-col gap-10">

                  <div>
                      <NumberInput
                          label="Building height"
                          description="Lowest external ground level to the roof line"
                          value={form.buildingHeight?.toString() ?? ""}
                          unit="m"
                          step={0.01}
                          fullUnit="metres"
                          onChange={(newValue: string) =>
                              setForm({
                                  ...form,
                                  buildingHeight:
                                      parseFloat(newValue),
                              })}
                          mandatory={true}
                      />
                  </div>

                  <div>
                      <NumberInput
                          label="Number of storeys"
                          value={form.numberOfStoreys?.toString() ?? ""}
                          unit=""
                          fullUnit=""
                          onChange={(numberOfStoreys: string) =>
                              setForm({
                                  ...form,
                                  numberOfStoreys:
                                      numberOfStoreys === "" ? null : parseInt(numberOfStoreys),
                              })
                          }
                      />
                  </div>

                  {form.numberOfStoreys !== null && form.numberOfStoreys >= 3 &&
                      formState.state === FormState.inputtingData &&(
                      <div>
                          <NumberInput
                              label="Wind loads"
                              mandatory={true}
                              value={form.windLoads?.toString() ?? ""}
                              unit=""
                              fullUnit=""
                              onChange={(newValue: string) =>
                                  setForm({
                                      ...form,
                                      windLoads:
                                          parseInt(newValue),
                                  })}

                          />
                      </div>
                  )}

                  <div>
                      <NumberInput
                          label="U-Value target"
                          value={form.uvalueTarget?.toString() ?? ""}
                          step={0.01}
                          onChange={(newValue: string) =>
                              setForm({
                                  ...form,
                                  uvalueTarget:
                                      parseFloat(newValue),
                              })}
                          mandatory={true}
                      />
                  </div>

                  <div>
                      <DateInput
                          label="Date access available for pull out tests"
                          mandatory={true}
                          value={form.dateAccessAvailableForPullOutTests}
                          onChange={(date) => setForm({ ...form, dateAccessAvailableForPullOutTests: date })}
                          minDate={new Date()}
                      />
                  </div>

                  <div className="flex">
                      <Button
                          label="Previous page"
                          onClickFunction={GotoPage3}
                      />
                      {   (form.buildingHeight  > 0) &&
                          (form.uvalueTarget > 0) &&
                          formState.state === FormState.inputtingData &&
                          (
                              <Button
                                  label="Next page"
                                  onClickFunction={GotoPage5}
                              />
                          )}
                  </div>
              </div>
          )}

          {currentPage.state === CurrentPage.page5 &&
              formState.state === FormState.inputtingData &&(
              <div className="flex flex-col gap-10">

                  <Choice<number>
                      label="Preferred insulation type"
                      mandatory={true}
                      multi={false}
                      options={dropDownLists.preferredInsulationType.map(({ item, value }) => ({
                          label: item,
                          value: parseInt(value),
                      }))}
                      value={form.preferredInsulationType}
                      onChange={(preferredInsulationType) => setForm({ ...form, preferredInsulationType })}
                  />

                  <div>
                      <StringInput
                          label="Any previous structural issues?"
                          mandatory={true}
                          value={form.anyPreviousStructuralIssues}
                          onChange={(newValue) => setForm({...form, anyPreviousStructuralIssues: newValue})}
                      />
                  </div>

                  <Choice<boolean>
                      label="Cavity insulation present?"
                      mandatory={false}
                      multi={false}
                      options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                      ]}
                      value={form.cavityInsulationPresent}
                      onChange={(cavityInsulationPresent) => setForm({ ...form, cavityInsulationPresent })}
                  />

                  <Choice<boolean>
                      label="Sufficient overhang at eaves?"
                      mandatory={false}
                      multi={false}
                      options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                      ]}
                      value={form.sufficientOverhangAtEaves}
                      onChange={(sufficientOverhangAtEaves) => setForm({ ...form, sufficientOverhangAtEaves })}
                  />

                  <Choice<boolean>
                      label="Window replacement program?"
                      mandatory={false}
                      multi={false}
                      options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                      ]}
                      value={form.windowReplacementProgram}
                      onChange={(windowReplacementProgram) => setForm({ ...form, windowReplacementProgram })}
                  />

                  <Choice<boolean>
                      label="Sufficient overhang on cills?"
                      mandatory={false}
                      multi={false}
                      options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                      ]}
                      value={form.sufficientOverhangOnCills}
                      onChange={(sufficientOverhangOnCills) => setForm({ ...form, sufficientOverhangOnCills })}
                  />

                  <div className="flex">
                      <Button
                          label="Previous page"
                          onClickFunction={GotoPage4}
                      />
                      {   (form.preferredInsulationType|| 0 > 0) &&
                          (form.anyPreviousStructuralIssues !== "") &&
                          formState.state === FormState.inputtingData &&
                          (
                              <Button
                                  label="Next page"
                                  onClickFunction={GotoPage6}
                              />
                          )}
                  </div>
              </div>
          )}

          {currentPage.state === CurrentPage.page6 &&
              formState.state === FormState.inputtingData &&(
              <div className="flex flex-col gap-10">

                  <Choice<boolean>
                      label="Room for insulation in the reveals?"
                      mandatory={false}
                      multi={false}
                      options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                      ]}
                      value={form.roomForInsulationInTheReveals}
                      onChange={(roomForInsulationInTheReveals) => setForm({ ...form, roomForInsulationInTheReveals })}
                  />

                  <Choice<boolean>
                      label="Insulation required below DPC?"
                      mandatory={false}
                      multi={false}
                      options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                      ]}
                      value={form.insulationRequiredBelowDPC}
                      onChange={(insulationRequiredBelowDPC) => setForm({ ...form, insulationRequiredBelowDPC })}
                  />

                  <Choice<boolean>
                      label="Any ceilings or walkways needing insulation?"
                      mandatory={false}
                      multi={false}
                      options={[
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                      ]}
                      value={form.anyCeilingsOrWalkwaysNeedingInsulation}
                      onChange={(anyCeilingsOrWalkwaysNeedingInsulation) => setForm({ ...form, anyCeilingsOrWalkwaysNeedingInsulation })}
                  />

                  <div className="flex">
                      <Button
                          label="Previous page"
                          onClickFunction={GotoPage5}
                      />
                      {   formState.state === FormState.inputtingData &&
                          (
                              <div>
                                  <Button
                                      label="Submit"
                                      onClickFunction={SubmitPressed}
                                  />

                              </div>
                          )}
                  </div>
              </div>
          )}

          {formState.state === FormState.SubmitPressed &&
              (
                  <ProjectInformationRequest
                      BuildingType= {form.buildingType ?? 0}
                      BuildingTypeOther={form.buildingTypeOther}
                      AccessAvailableForPullOutTests= {form.dateAccessAvailableForPullOutTests}
                      AnyCeilingsOrWalkwaysNeedingInsulation= {form.anyCeilingsOrWalkwaysNeedingInsulation}
                      AnyPreviousStructuralIssues= {form.anyPreviousStructuralIssues}
                      BuildingHeight= {form.buildingHeight}
                      BuildingUse= {form.buildingUse ?? 0}
                      CavityInsulationPresent= {form.cavityInsulationPresent}
                      Company= {form.company}
                      ContactEmail= {form.contactEmail}
                      ContactName= {form.contactName}
                      ContactTelNumber= {form.contactTel}
                      DistanceToBoundary= {form.distanceToBoundary}
                      InsulationRequiredBelowDPC= {form.insulationRequiredBelowDPC}
                      MainContractor= {form.mainContractor}
                      NewBuild= {form.newBuild}
                      PreferredInsulationType= {form.preferredInsulationType?? 0}
                      Project= {form.project}
                      RequestDate= {new Date()}
                      RequestedBy= {form.requestedBy}
                      NoOfStoreys= {form.numberOfStoreys ?? 0}
                      RoomForInsulationInTheReveals= {form.roomForInsulationInTheReveals}
                      RoomInRoof= {form.roomInRoof}
                      SufficientOverhangOnCills= {form.sufficientOverhangOnCills}
                      SufficientOverhangAtEaves= {form.sufficientOverhangAtEaves}
                      SystemFinishRequired= {GetSystemFinish()}
                      ProjectAddress= {form.projectAddress}
                      UvalueTarget= {form.uvalueTarget}
                      WindLoads= {form.windLoads}
                      WindowReplacementProgram= {form.windowReplacementProgram}
                      ThroughWallConstruction= {form.throughWallConstruction}
                      ProjectSize= {form.projectSize}
                  />
              )}

      </div>

      </>



  );
};

export default RequestPip;
