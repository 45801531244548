import { uniqueId } from "lodash";
import { useState } from "react";
import ChoiceButton from "./ChoiceButton";
import Label from "./Label";

type ChoiceType<T> = {
  label: string;
  mandatory?: boolean;
  labelSize?: "sm" | "lg";
  description?: string;
  options: {
    label: string;
    value: T;
  }[];
} & (
  | { multi: true; value: T[]; onChange: (arg0: T[]) => void }
  | { multi: false; value: T | null; onChange: (arg0: T | null) => void }
);

function Choice<T>({
  label,
  mandatory = false,
  labelSize = "lg",
  description,
  options,
  multi,
  value,
  onChange,
}: React.PropsWithChildren<ChoiceType<T>>) {
  const [id] = useState(uniqueId("choice-"));

  const selected = Array.isArray(value) ? value : [value];

  return (
    <fieldset>
      <Label
        type="legend"
        textSize={labelSize}
        label={label}
        mandatory={mandatory}
        description={description}
      />
      <div className="flex gap-4 flex-wrap">
        {options.map((o, index) => (
          <ChoiceButton<T>
            key={o.label}
            index={index}
            multi={multi}
            label={o.label}
            id={id}
            checked={selected.includes(o.value)}
            onChange={(checked) => {
              if (!multi && checked) {
                // @ts-expect-error
                onChange(o.value);
                return;
              }

              if (!multi) return;

              const newSelected = checked
                ? [...selected, o.value].filter(
                    // Remove duplicates
                    (value, index, self) => self.indexOf(value) === index
                  )
                : selected.filter((v) => v !== o.value);

              // @ts-expect-error
              onChange(newSelected);
            }}
          />
        ))}
      </div>
    </fieldset>
  );
}

export default Choice;
