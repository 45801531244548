import { FunctionComponent } from "react";

const Asterisk: FunctionComponent = () => {
  return (
    <svg
      width="7"
      height="7"
      viewBox="0 0 7 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <rect
        x="2.72131"
        width="1.27273"
        height="7"
        rx="0.636364"
        fill="currentColor"
      />
      <rect
        x="6.06213"
        y="1.27274"
        width="1.27273"
        height="7"
        rx="0.636364"
        transform="rotate(60 6.06213 1.27274)"
        fill="currentColor"
      />
      <rect
        x="6.69849"
        y="4.77274"
        width="1.27273"
        height="7"
        rx="0.636364"
        transform="rotate(120 6.69849 4.77274)"
        fill="currentColor"
      />
    </svg>
  );
};

export default Asterisk;
