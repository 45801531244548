import { FunctionComponent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useApiResponseStore from "../../stores/useApiResponseStore";
import useReportStore from "../../stores/useReportStore";
import Button from "../base/Button";

const CreateReportSuccess: FunctionComponent = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [report] = useReportStore();
  const [apiResponse] = useApiResponseStore();

  const navigate = useNavigate();
  useEffect(() => {
    if (
      typeof apiResponse?.projectId !== "number" ||
      apiResponse?.projectId <= 0
    ) {
      navigate("/");
    }
  }, []);

  const sendEmail = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ProjectId: apiResponse?.projectId,
      }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/api/SendReportByEmail`,
      requestOptions
    );
    const json = await response.json();
    console.log(JSON.stringify(json));
    // TODO: Check for a good reponse and give a success message
    setEmailSent(true);
  };

  const openInNewTab = () => {
    window.open(report.downloadUrl, "_blank");
  };

  return (
    <>
      <h1 className="mt-4 mb-6 text-3xl col-span-full">Report Created</h1>
      <div>
        {!emailSent && (
          <>
            <Button
              label="Send Report via Email"
              onClickFunction={sendEmail}
            />
            <br />
          </>
        )}

        {report.downloadUrl !== "" && (
          <>
            <Button label="View Report" onClickFunction={openInNewTab} />
            <br/>
          </>
        )}
        <Link
          className="flex items-center justify-center bg-weber px-5 py-3 rounded-full hover:bg-weber-hover transition-colors"
          to="/request-pip"
        >
          Request Project Information Pack
        </Link>
        <br />
        <a
            className="flex items-center justify-center bg-weber px-5 py-3 rounded-full hover:bg-weber-hover transition-colors"
            href="/"
        >
          New Calculation
        </a>
      </div>
    </>
  );
};

//<Button label="New Calculation" onClickFunction={} />
export default CreateReportSuccess;
