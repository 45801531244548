import {uniqueId} from "lodash";
import { FunctionComponent, useState } from "react";
import validator from "validator";
import Label from "./Label";

const EmailInput: FunctionComponent<{
  label: string;
  mandatory: boolean;
  labelSize?: "sm" | "lg";
  description?: string;
  onChange: (value: string) => any;
}> = ({ label, mandatory, description, labelSize = "lg", onChange }) => {
  const [id] = useState(uniqueId("email-"));

  const [emailError, setEmailError] = useState("");
  const validateEmail = (e) => {
    var email = e.target.value;

    if (validator.isEmail(email)) {
      onChange(email);
      setEmailError("");
    } else {
      onChange("");
      setEmailError("Please enter valid email address");
    }
  };
  return (
    <>
      <Label
        type="label"
        label={label}
        mandatory={mandatory}
        textSize={labelSize}
        description={description}
        htmlFor={id}
      />
      <input
        type="text"
        autoCapitalize="none"
        id={id}
        className="px-3 py-2 bg-transparent border border-gray-900 rounded w-full focus:outline-light"
        onChange={(e) => validateEmail(e)}
      />
      <span
        style={{
          fontWeight: "bold",
          color: "red",
        }}
      >
        {emailError}
      </span>
    </>
  );
};
export default EmailInput;
