import { uniqueId } from "lodash";
import { FunctionComponent, useState } from "react";

const CheckBox: FunctionComponent<{
  label: string | JSX.Element;
  value: string;
  mandatory?: boolean;
  onChange: () => void;
}> = ({ label, value, mandatory = false, onChange }) => {
  const [id] = useState(uniqueId("string-"));

  return (
    <>
      <div className="flex justify-between">
        <label className="text-lg" htmlFor={id}>
          <span>{label}</span>
          {mandatory && <span className="ml-1 text-red-700" aria-label="(required)">*</span>}
        </label>
        <input id={id} type="checkbox" value={value} onChange={onChange} />
      </div>
    </>
  );
};

export default CheckBox;
