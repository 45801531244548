import { useEffect, useState } from "react";

export interface IApiResponse {
  uvalue: string;
  downloadUrl: string;
  projectId: number;
}

let calculationStore: IApiResponse | null = null;

export default (): [
  IApiResponse | null,
  React.Dispatch<React.SetStateAction<IApiResponse | null>>
] => {
  const state = useState<IApiResponse | null>(calculationStore);

  useEffect(() => {
    calculationStore = state[0];
  }, [state[0]]);

  return state;
};
