import { FunctionComponent } from "react";
import Asterisk from "./Asterisk";

const MandatoryLabel: FunctionComponent = ({}) => {

  return (
    <div >
      <label className={"flex items-center gap-2 text-base"}>
         (
        <span className="text-red-700">
            <span className="sr-only">(required)</span>
            <Asterisk />
          </span>
        mandatory)
      </label>
    </div>
  );
};

export default MandatoryLabel;
