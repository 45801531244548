function ChoiceButton<T>({
  multi,
  label,
  id,
  index,
  checked,
  onChange,
}: React.PropsWithChildren<{
  multi: boolean;
  label: string;
  id: string;
  index: number;
  checked: boolean;
  onChange: (arg0: boolean) => void;
}>) {
  const htmlFor = id + "-" + index;
  const labelClasses = [
    "flex",
    "items-center",
    "gap-4",
    "rounded",
    "px-4",
    "py-2",
    "grow",
    ...(checked
      ? ["bg-gray-700", "text-gray-50"]
      : ["bg-gray-50", "hover:bg-gray-100", "cursor-pointer"]),
  ];
  const checkboxClasses = [
    "inline-flex",
    "items-center",
    "justify-center",
    "w-4",
    "h-4",
    multi ? "rounded" : "rounded-full",
    "bg-gray-200",
    "peer-checked:bg-weber",
    "flex-shrink-0",
  ];

  return (
    <label htmlFor={htmlFor} className={labelClasses.join(" ")}>
      <input
        type={multi ? "checkbox" : "radio"}
        id={htmlFor}
        name={id}
        value={index}
        checked={checked}
        onChange={(e) => onChange(!checked)}
        className="hidden peer"
      />
      <span className={checkboxClasses.join(" ")}>
        {checked && (
          <svg
            width="8.2"
            height="8"
            viewBox="0 0 8.2 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.833983 4.23398L0 5.06797L2.41732 7.48528L8.21888 1.68372L7.38489 0.849739L2.41732 5.81732L0.833983 4.23398Z"
              fill="black"
            />
          </svg>
        )}
      </span>
      {label}
    </label>
  );
}

export default ChoiceButton;
