import { useEffect, useState } from "react";

interface IDropDownItem {
    item: string;
    value: string;
}

interface IDropDownLists {
    buildingType: IDropDownItem[];
    buildingUse: IDropDownItem[];
    externalWallInsulation: IDropDownItem[];
    insulationXm: IDropDownItem[];
    insulationXp: IDropDownItem[];
    preferredInsulationType: IDropDownItem[];
    substrate: IDropDownItem[];
    thicknesses_40_100: IDropDownItem[];
    thicknesses_20_250: IDropDownItem[];
    thicknesses_30_200: IDropDownItem[];
    thicknesses_30_250: IDropDownItem[];
}

const useDownLists = () => {
    const [dropDownLists, setDropDownLists] = useState<IDropDownLists>({
        buildingType: [],
        buildingUse: [],
        externalWallInsulation: [],
        insulationXm: [],
        insulationXp: [],
        preferredInsulationType: [],
        substrate: [],
        thicknesses_40_100: [],
        thicknesses_20_250:[],
        thicknesses_30_200:[],
        thicknesses_30_250:[],
    });

    useEffect(() => {
        (async () => {
            const response = await fetch(`${process.env.REACT_APP_API_PATH}/api/GetDropDowns`);
            const jsonResponse = await response.json();
            //console.log(jsonResponse)

            setDropDownLists({
                ...dropDownLists,
                buildingType: jsonResponse?.buildingType || [],
                buildingUse: jsonResponse?.buildingUse || [],
                externalWallInsulation: jsonResponse?.externalWallInsulation || [],
                insulationXm: jsonResponse?.insulationXm || [],
                insulationXp: jsonResponse?.insulationXp || [],
                preferredInsulationType: jsonResponse?.preferredInsulationType || [],
                substrate: jsonResponse?.substrate || [],
                thicknesses_40_100: jsonResponse?.thicknesses_40_100 || [],
                thicknesses_20_250: jsonResponse?.thicknesses_20_250 || [],
                thicknesses_30_200: jsonResponse?.thicknesses_30_200 || [],
                thicknesses_30_250: jsonResponse?.thicknesses_30_250 || [],
            });
        })();
    }, []);

    return dropDownLists;
};
export default
useDownLists
;
