import { FunctionComponent } from "react";
import Asterisk from "./Asterisk";

type LabelType = {
  label: string;
  mandatory: boolean;
  textSize: "sm" | "lg";
  hiddenLabel?: string;
  description?: string;
} & ({ type: "label"; htmlFor: string } | { type: "legend" });

const Label: FunctionComponent<LabelType> = ({
  type,
  label,
  hiddenLabel,
  description,
  textSize,
  mandatory,
  ...props
}) => {
  const Tag = type;
  const sizeClass = {
    sm: "text-base",
    lg: "text-lg",
  }[textSize];

  return (
    <div className="mb-2">
      <Tag {...props} className={"flex items-center gap-2 " + sizeClass}>
        {label}
        {hiddenLabel && <span className="sr-only">{hiddenLabel}</span>}
        {mandatory && (
          <span className="text-red-700">
            <span className="sr-only">(required)</span>
            <Asterisk />
          </span>
        )}
      </Tag>
      {description && (
        <div className="text-sm text-gray-700">{description}</div>
      )}
    </div>
  );
};

export default Label;
