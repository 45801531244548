import { useEffect, useState } from "react";

export const defaultData = {
  downloadUrl: "",
};

let store: {
  downloadUrl: string;
} = { ...defaultData };

export default () => {
  const state = useState(store);

  useEffect(() => {
    store = state[0];
  }, [state[0]]);

  return state;
};
