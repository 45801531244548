import { FunctionComponent, useEffect, useState } from "react";
import IApiObject from "./IApiObject";
import ErrorMessage from "./base/ErrorMessage";
import useApiResponseStore from "../stores/useApiResponseStore";

const CalculationResult: FunctionComponent<{
  apiObject: IApiObject;
}> = ({ apiObject }) => {
  const [errors, setErrors] = useState<Array<{ value: string }>>([]);
  const [errorStr, setErrorStr] = useState("");
  const [uvalue, setUvalue] = useState("");
  const [upperLimit, setUpperLimit] = useState("");
  const [lowerLimit, setLowerLimit] = useState("");
  const [dUf, setDUf] = useState("");
  const [dUg, setDUg] = useState("");
  const [dUp, setDUp] = useState("");
  const [dUr, setDUr] = useState("");
  const [dUrc1, setDUrc1] = useState("");
  const [dUrc2, setDUrc2] = useState("");

  const [apiResponse, setApiResponse] = useApiResponseStore();

  useEffect(() => {
    (async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ProjectId: apiResponse?.projectId,
          WeberProductCB: apiObject.weberProductCB,
          SubstrateCB: apiObject.substrateCB,
          InsulationCB: apiObject.insulationCB,
          InsulationThicknessCB: apiObject.insulationThicknessCB,
        }),
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_PATH}/api/getUvalue`,
        requestOptions
      );
      const json = await response.json();

      setErrorStr(json?.errorStr || "");
      setErrors(json?.errors || []);
      setUvalue(json.uvalue);
      setUpperLimit(json.upperLimit);
      setLowerLimit(json.lowerLimit);
      setDUf(json.dUf);
      setDUg(json.dUg);
      setDUp(json.dUp);
      setDUr(json.dUr);
      setDUrc1(json.dUrc1);
      setDUrc2(json.dUrc2);

      setApiResponse({
        projectId: json.projectId,
        downloadUrl: json.downloadUrl || "",
        uvalue: json.uvalue,
      });
      localStorage.setItem(
          "uValue",
          json.uvalue
      );

    })();
  }, [apiObject, setApiResponse]);

  const hasErrors = errors.length > 0 || errorStr.length > 0;

  return (
    <>
      <div className="p-8">
        {!hasErrors && (
          <>
            <p className="font-bold text-2xl align-middle">
              U-value, Combined Method : {uvalue} W/m²K
            </p>

            <dl className="py-4 text-sm grid grid-cols-2">
              <dt>upper/lower limit</dt>
              <dd>
                {upperLimit.toString()} / {lowerLimit.toString()}m²K/W
              </dd>

              <dt>dUf</dt>
              <dd>{dUf}</dd>

              <dt>dUg</dt>
              <dd>{dUg}</dd>

              <dt>dUp</dt>
              <dd>{dUp}</dd>

              <dt>dUr</dt>
              <dd>{dUr}</dd>

              <dt>dUrc1</dt>
              <dd>{dUrc1}</dd>

              <dt>dUrc2</dt>
              <dd>{dUrc2}</dd>
            </dl>
          </>
        )}

        {hasErrors && (
          <>
            <ErrorMessage label="Error getting U-Value. Please contact Technical Support." />
            <p></p>
            <ErrorMessage label={errorStr} />
            {errors.length > 0 && (
              <p>
                <ErrorMessage label={JSON.stringify(errors)} />
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default CalculationResult;
