import { uniqueId } from "lodash";
import { FunctionComponent, useState } from "react";
import Label from "./Label";

const StringInput: FunctionComponent<{
  label: string;
  labelSize?: "sm" | "lg";
  description?: string;
  mandatory?: boolean;
  value: string;
  onChange: (value: string) => any;
}> = ({ label, labelSize = "lg", description, value, mandatory = false, onChange }) => {
  const [id] = useState(uniqueId("string-"));

  return (
    <>
      <Label
        type="label"
        label={label}
        mandatory={mandatory}
        description={description}
        textSize={labelSize}
        htmlFor={id}
      />
      <input
        id={id}
        type="text"
        name={label}
        autoComplete={label}
        value={value}
        className="px-3 py-2 bg-transparent border border-gray-900 rounded w-full focus:outline-light"
        onChange={(e) => onChange(e.target.value)}
      />
    </>
  );
};
export default StringInput;

