import { useEffect, useState } from "react";
import IApiDetails from "../components/IApiDetails";

export const defaultData: IApiDetails = {
  email: "",
  firstname: "",
  surname: "",
  company: "",
  postcode: "",
  projectRef: "",
};

let store: IApiDetails = {
  ...defaultData,
};

export default () => {
  const state = useState(store);

  useEffect(() => {
    store = state[0];
  }, [state[0]]);

  return state;
};
