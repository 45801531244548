import { FunctionComponent, useEffect, useState } from "react";
import StringInput from "../base/StringInput";
import useDetailsStore from "../../stores/useDetailsStore";
import EmailInput from "../base/EmailInput";
import CheckBox from "../base/CheckBox";
import Button from "../base/Button";
import useReportStore from "../../stores/useReportStore";
import { useNavigate } from "react-router-dom";
import useApiResponseStore from "../../stores/useApiResponseStore";
import Asterisk from "../base/Asterisk";
import MandatoryLabel from "../base/MandatoryLabel";

const CreateReport: FunctionComponent = () => {
  const [details, setDetails] = useDetailsStore();
  const [apiResponse] = useApiResponseStore();

  const [disclaimerChecked, setDisclaimerChecked] = useState(false);
  const [contactAgreementChecked, setContactAgreementChecked] = useState(false);

  const [_, setReport] = useReportStore();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      typeof apiResponse?.projectId !== "number" ||
      apiResponse?.projectId <= 0
    ) {
      navigate("/");
    }
  }, []);

  const onSubmit = async function () {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ProjectId: apiResponse?.projectId,
        EmailAddress: details.email,
        Firstname: details.firstname,
        Surname: details.surname,
        Company: details.company,
        Postcode: details.postcode,
        ProjectReference: details.projectRef,
      }),
    };
    const response = await fetch(
      `${process.env.REACT_APP_API_PATH}/api/generateReport`,
      requestOptions
    );
    const json = await response.json();

    if (typeof json?.errorStr === "string" && json.errorStr !== "") {
      // TODO: Handle errors here
    }

    setReport({
      downloadUrl: json?.downloadUrl || "",
    });

    navigate("/create-report/success");
  };

  return (
    <>
    <h1 className="mt-4 text-3xl col-span-full">Enter Details</h1>
    <MandatoryLabel />
      <div className="flex flex-col gap-6 col-start-1">
        <div className="grid gap-2">
          <div className="grid gap-2">
            <StringInput
              label="First name"
              value={details.firstname}
              mandatory={true}
              onChange={(e) => setDetails({ ...details, firstname: e })}
            />
          </div>
        </div>

        <div className="grid gap-2">
          <StringInput
            label="Surname"
            mandatory={true}
            value={details.surname}
            onChange={(e) => setDetails({ ...details, surname: e })}
          />
        </div>

        <div className="grid gap-2">
          <EmailInput
            label="Email"
            mandatory={true}
            onChange={(e) => setDetails({ ...details, email: e })}
          />
        </div>

        <StringInput
          label="Company"
          mandatory={false}
          value={details.company}
          onChange={(e) => setDetails({ ...details, company: e })}
        />
        <StringInput
            label="Postcode"
            mandatory={true}
            value={details.postcode}
            onChange={(e) => setDetails({ ...details, postcode: e })}
        />
        <StringInput
          label="Project Reference"
          mandatory={false}
          value={details.projectRef}
          onChange={(e) => setDetails({ ...details, projectRef: e })}
        />
        <div className="weberborder webercheckbox">
          <div className="text-lg float-left w-11/12">
            I have read, understood and agree to the the disclaimer,
            <a
              className="App-link"
              href="https://www.uk.weber/privacy-statement"
              target="_blank"
            >
              {" "}
              privacy statement{" "}
            </a>
            and
            <a className="App-link" href="https://www.uk.weber/terms-use" target="_blank">
              {" "}
              terms of use
            </a>
            .
          </div>
          <label className="float-left p-1 text-red-700"> *</label>
          <input
            type="checkbox"
            className="float-right"
            value="false"
            onChange={() => setDisclaimerChecked(!disclaimerChecked)}
          />
        </div>
        <CheckBox
          label="I agree to be contacted by the specifications team"
          value="false"
          mandatory={true}
          onChange={() => setContactAgreementChecked(!contactAgreementChecked)}
        />

        {details.surname != "" &&
          details.firstname != "" &&
          details.email != "" &&
          disclaimerChecked &&
          contactAgreementChecked && (
            <>
              <Button label="Create Report" onClickFunction={onSubmit} />
            </>
          )}
      </div>
    </>
  );
};

export default CreateReport;
