import { FunctionComponent } from "react";

const BuildUpImage: FunctionComponent<{
  filename: string;
}> = ({ filename }) => {
  return (
    <>
      <img className="w-3/5 pl-16" src={filename} alt="XM MFD" />
    </>
  );
};

export default BuildUpImage;

