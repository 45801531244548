import { FunctionComponent } from "react";

const Button: FunctionComponent<{
  label: string | JSX.Element;
  onClickFunction: () => void;
}> = ({ label, onClickFunction }) => {
  return (
    <>
      <button
        type="button"
        className="font-bold flex items-center w-full justify-center bg-weber px-5 py-3 rounded-full hover:bg-weber-hover transition-colors"
        onClick={onClickFunction}
      >
        {label}
      </button>
    </>
  );
};

export default Button;
