import { useEffect, useState } from "react";
import IApiObject from "../components/IApiObject";

export const defaultData: IApiObject = {
  weberProductCB: "-1",
  substrateCB: "-1",
  insulationCB: "-1",
  insulationThicknessCB: "-1",
};

let calculationStore: IApiObject = {
  ...defaultData,
};

export default (): [
  IApiObject,
  React.Dispatch<React.SetStateAction<IApiObject>>
] => {
  const state = useState(calculationStore);

  useEffect(() => {
    calculationStore = state[0];
  }, [state[0]]);

  return state;
};
