import {FunctionComponent} from "react";

const  ErrorMessage: FunctionComponent<{
    label: string | JSX.Element;

}> = ({
          label,
      }) => {
    return (
        <label className="p-2 text-red-700">
            {label}
        </label>
    );
};

export default ErrorMessage;
